import React, { Component } from "react";
import "./Header.css";
import { contact } from "../../props";

class Header extends Component {
  render() {
    return (
      <div className="sticky-header1">
        <nav className="navbar bg-body-tertiary">
          <div className="container-xxl">
            <a class="navbar-brand ps-1" href="#">
              <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="150" height="" viewBox="0 0 2475.000000 368.000000" preserveAspectRatio="xMidYMid meet" className="logo-svg">
                <g transform="translate(0.000000,368.000000) scale(0.100000,-0.100000)" fill="#122d42" stroke="none">
                  <path d="M6600 1830 l0 -1410 290 0 290 0 0 565 0 565 155 0 155 0 0 -565 0 -565 285 0 285 0 0 1410 0 1410 -285 0 -285 0 0 -600 0 -600 -155 0 -155 0 0 600 0 600 -290 0 -290 0 0 -1410z" fill="#122d42" />
                  <path d="M8725 3218 c-5 -21 -505 -2789 -505 -2795 0 -2 128 -3 283 -3 l284 0 24 173 24 172 195 0 195 0 25 -172 24 -173 284 0 284 0 -6 28 c-3 15 -116 641 -251 1392 -135 751 -248 1373 -251 1383 -5 16 -28 17 -304 17 l-300 0 -5 -22z m375 -1515 c33 -260 60 -475 60 -478 0 -3 -59 -5 -131 -5 l-131 0 6 38 c3 20 31 228 61 462 56 430 66 497 72 470 1 -8 30 -227 63 -487z" fill="#122d42" />
                  <path d="M10000 1830 l0 -1410 280 0 280 0 0 450 0 450 39 0 c29 0 41 -5 45 -17 30 -104 241 -800 252 -836 l16 -47 289 0 c159 0 289 2 289 5 0 2 -66 203 -146 446 -80 243 -144 442 -142 443 2 2 29 8 61 15 68 15 114 51 155 120 l27 46 0 745 0 745 -23 56 c-32 79 -80 131 -154 165 l-63 29 -602 3 -603 3 0 -1411z m878 908 c9 -9 12 -128 12 -474 0 -417 -2 -463 -17 -475 -12 -11 -53 -14 -162 -14 l-146 0 -3 488 -2 487 153 0 c106 0 157 -4 165 -12z" fill="#122d42" />
                  <path d="M11920 3226 c-25 -7 -59 -20 -76 -29 -42 -22 -101 -89 -127 -145 -22 -46 -22 -55 -25 -636 -3 -671 -4 -665 71 -739 63 -63 89 -69 364 -76 178 -4 245 -9 255 -19 10 -10 14 -80 16 -295 2 -155 0 -292 -2 -303 -10 -39 -52 -44 -367 -44 -165 0 -299 -3 -299 -7 -1 -5 21 -122 47 -261 l48 -253 420 4 c437 4 467 6 551 50 47 24 108 99 130 159 17 49 19 93 22 636 2 500 1 591 -12 636 -21 71 -45 99 -114 133 -57 28 -58 28 -309 33 -188 4 -255 8 -265 18 -20 20 -24 580 -4 607 14 19 28 20 356 23 l342 3 -5 22 c-3 12 -25 129 -48 260 l-43 237 -441 -1 c-319 -1 -452 -4 -485 -13z" fill="#122d42" />
                  <path d="M13190 1830 l0 -1410 285 0 285 0 0 565 0 565 155 0 155 0 0 -565 0 -565 290 0 290 0 0 1410 0 1410 -290 0 -290 0 0 -600 0 -600 -155 0 -155 0 0 600 0 600 -285 0 -285 0 0 -1410z" fill="#122d42" />
                  <path d="M15062 1838 c-139 -772 -253 -1406 -252 -1410 0 -5 126 -8 280 -8 l280 0 5 23 c5 25 45 304 45 318 0 5 83 9 195 9 l194 0 6 -32 c3 -18 14 -96 25 -172 11 -77 22 -141 24 -143 1 -2 129 -2 283 -1 l280 3 -233 1290 c-128 710 -243 1343 -255 1408 l-21 117 -302 0 -301 0 -253 -1402z m622 -123 c31 -247 59 -460 61 -472 5 -23 5 -23 -125 -23 -97 0 -130 3 -130 13 -1 47 126 965 132 950 3 -10 32 -220 62 -468z" fill="#122d42" />
                  <path d="M17070 1830 l0 -1411 583 3 c569 4 583 4 648 26 123 41 184 107 214 234 20 84 22 937 3 1002 -19 61 -93 133 -146 142 -23 4 -42 10 -42 13 0 4 16 13 35 20 52 18 100 72 121 133 18 49 19 91 19 528 0 468 0 476 -22 530 -30 74 -74 125 -137 158 l-51 27 -612 3 -613 3 0 -1411z m885 904 c14 -14 15 -55 13 -356 -3 -323 -4 -340 -22 -354 -15 -11 -56 -14 -168 -14 l-148 0 0 370 0 370 154 0 c129 0 157 -3 171 -16z m-4 -1173 l24 -19 0 -302 c0 -289 -1 -302 -20 -316 -15 -11 -51 -14 -170 -12 l-150 3 -3 333 -2 332 149 0 c132 0 152 -2 172 -19z" fill="#122d42" />
                  <path d="M18770 1830 l0 -1410 524 0 524 0 6 27 c4 16 26 133 50 261 l43 232 -284 0 -283 0 0 1150 0 1150 -290 0 -290 0 0 -1410z" fill="#122d42" />
                  <path d="M20292 1837 c-139 -771 -252 -1405 -252 -1410 0 -4 127 -6 282 -5 l283 3 24 172 24 173 193 0 193 0 6 -32 c11 -69 45 -297 45 -307 0 -8 254 -11 849 -11 801 0 850 1 855 18 3 9 24 118 46 242 23 124 43 233 46 243 5 16 -12 17 -261 17 -250 0 -267 1 -263 18 57 206 598 2263 598 2271 0 8 -173 11 -608 11 l-608 0 -43 -237 c-23 -131 -45 -248 -48 -260 l-5 -23 291 0 c240 0 291 -2 291 -14 0 -13 -558 -2040 -582 -2116 -6 -19 -13 -30 -15 -25 -1 6 -109 597 -238 1315 -129 718 -238 1317 -241 1333 l-6 27 -301 0 -302 0 -253 -1403z m622 -117 c32 -250 60 -465 63 -477 5 -23 5 -23 -126 -23 -72 0 -131 3 -131 8 -2 30 126 960 131 954 3 -4 32 -212 63 -462z" fill="#122d42" />
                  <path d="M23120 1830 l0 -1410 540 0 c297 0 540 3 540 8 0 4 20 115 45 247 25 132 45 246 45 252 0 10 -68 13 -292 15 l-293 3 -3 308 -2 307 235 0 235 0 0 245 0 245 -235 0 -235 0 0 335 0 335 311 0 c291 0 310 1 305 18 -3 9 -23 118 -46 242 -22 124 -43 233 -46 243 -5 16 -40 17 -555 17 l-549 0 0 -1410z" fill="#122d42" />
                  <path d="M1485 2849 c-22 -5 -53 -16 -68 -25 -16 -9 -214 -203 -440 -432 -594 -601 -660 -669 -656 -680 2 -5 243 -250 534 -545 575 -582 574 -580 680 -579 181 1 299 205 209 359 -18 31 -160 184 -366 393 -330 336 -338 343 -360 328 -98 -64 -140 -89 -137 -80 2 5 191 199 419 431 230 234 427 442 442 468 35 61 43 120 24 187 -36 123 -161 200 -281 175z" fill="#59f3e6" />
                  <path d="M3118 2848 c-57 -12 -129 -71 -160 -131 -18 -37 -371 -853 -669 -1549 -106 -249 -119 -284 -119 -336 0 -101 65 -196 160 -234 33 -13 138 -13 172 1 39 15 106 79 128 120 15 30 483 1111 716 1656 65 151 69 164 69 235 -1 84 -14 116 -67 169 -65 64 -143 88 -230 69z" fill="#59f3e6" />
                  <path d="M4022 2849 c-113 -22 -192 -124 -192 -246 1 -104 12 -119 368 -478 180 -182 377 -381 437 -444 l110 -113 -90 56 -90 55 -328 -327 c-180 -180 -344 -351 -364 -380 -31 -45 -38 -63 -41 -117 -7 -111 38 -194 132 -240 71 -35 133 -36 204 -5 46 20 125 96 587 564 315 319 531 545 527 552 -4 6 -247 255 -541 552 -374 379 -545 545 -570 555 -52 21 -100 26 -149 16z" fill="#59f3e6" />
                </g>
              </svg>
            </a>
            <span className="d-flex">
              {/* <h4 className="m-auto"><a className="nav-item pe-2 text-secondary" ref="noreferrer" target="_blank" href={contact.mail.link}><span className="iconify" data-icon={contact.mail.iconify}></span></a></h4> */}
              <h4 className="m-auto"><a className="nav-item pe-2" style={{ color: "rgb(156, 159, 160)", opacity: "1"}} ref="noreferrer" target="_blank" href={contact.linkedin.link}><span className="iconify" data-icon={contact.linkedin.iconify}></span></a></h4>
            </span>
          </div>
        </nav>
      </div>
    );
  }
}

export default Header;