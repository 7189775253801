//SEO Related settings
const seo = {
  title: "Sri Harsha's Portfolio",
  description:
    "I’m passionate about learning new technologies and solving challenging problems. I’m always eager to collaborate with other developers and share my knowledge and skills. I’m also open to new opportunities that can help me grow as a professional and as a person.",
  og: {
    title: "Sri Harsha's Portfolio",
    type: "portfolio website",
    url: "https://harshablaze.in",
  },
};


const contact = {
  linkedin:  {
    link: "https://www.linkedin.com/in/harshablaze",
    iconify: "cib:linkedin",
    icon: "ri:linkedin-fill",
  },
  mail: {
    link: "mailto:admin@harshablaze.in",
    iconify: "ic:baseline-email",
  },
  youtube: {
    link: "https://www.youtube.com/@HarshaBlaze/videos",
    iconify: "cib:youtube",
  },
  twitter: {
    link: "https://twitter.com/HarshaBlaze_",
    iconify: "fa6-brands:square-x-twitter",
  },
  github: {
    link: "https://github.com/harshablaze",
    iconify: "mdi:github",
  }
};

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "fullstack",
      skills: [
        "Create responsive websites using React, Bootstrap for the front-end.",
        "Develop backend applications using Node.js, TypeScript, and PostgreSQL as database.",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "BootStrap",
          fontAwesomeClassname: "devicon:bootstrap",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "vscode-icons:file-type-js-official",
          style: {
            backgroundColor: "transparent",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "TypeScript",
          fontAwesomeClassname: "devicon:typescript",
          style: {
            backgroundColor: "#ffffff",
            color: "#007acc",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "logos:nodejs",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "logos:mysql",
          style: {
            color: "#00758F",
          },
        },
        {
          skillName: "PHP",
          fontAwesomeClassname: "devicon:php",
          style: {
            color: "#777bb3",
          },
        },
      ],
      imageAttribution: "Freshcare",
      imageLink: "https://pngtree.com/freepng/website-page-under-construction-vector-illustration-concept_4888926.html?sol=downref&id=bef",
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "Infra",
      skills: [
        "I have expertise in design and development of distributed system applications that utilize AWS services, Kafka, and PostgreSQL, and I am also knowledgeable in Infrastructure as Code (IaC) and Serverless Framework scripts such as Docker, Terraform and Serverless.",
      ],
      softwareSkills: [
        {
          skillName: "AWS",
          fontAwesomeClassname: "skill-icons:aws-light",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Terraform",
          fontAwesomeClassname: "logos:terraform-icon",
        },
        {
          skillName: "Serverless",
          fontAwesomeClassname: "logos:serverless",
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
      ],
      // imageAttribution: "vectorjuice",
      // imageLink: "https://www.freepik.com/free-vector/two-developers-looking-gears-cloud-illustration_10780405.htm",
    },
    {
      title: "CI/CD - DevOps",
      fileName: "devops",
      skills: [
        "I use CI/CD to automate the deployment of the code from GitHub to AWS using GHA, Jenkins, JFrog Artifactory and XLRelease to manage and execute production deployments.",
      ],
      softwareSkills: [
        {
          skillName: "Jira",
          fontAwesomeClassname: "devicon:jira-wordmark",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "GitHub Actions",
          fontAwesomeClassname: "logos:github-actions",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Jenkins",
          fontAwesomeClassname: "logos:jenkins",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "JFrog",
          fontAwesomeClassname: "logos:jfrog",
          style: {
            color: "#336791",
          },
        },
        // {
        //   skillName: "SonarQube",
        //   fontAwesomeClassname: "logos:sonarqube",
        //   style: {
        //     color: "#336791",
        //   },
        // },
      ],
      imageAttribution: "freepik",
      imageLink: "https://www.freepik.com/free-vector/gradient-devops-illustration_25225458.htm#query=devops&position=33&from_view=keyword&track=sph",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Anil Neerukonda Institute of Technology & Sciences - Andhra University",
      subtitle: "Bachelor of Technology - BTech, Computer Science",
      logo_path: "anits.png",
      alt_name: "ANITS Visakhapatnam",
      duration: "Jul 2017 - Jun 2021",
      location: "Visakhapatnam, India",
      descriptions: [
        "🎓 Cumulative GPA: 8.43/10 • First Class with Distinction",
        "🏆 My rank of 4998 (Top 5%) in the A.P State EAMCET 2017 Entrance Exam earned me a full scholarship from the State Government, which covered my tuition and living expenses for the duration of my B.Tech course.",
        "🎯 During my BTech, I have achieved several academic milestones, such as publishing a research paper in Springer, participating in three projects, and undertaking a 10-month internship.",
      ],
      website_link: "https://anits.edu.in/",
    },
    {
      title: "SASI Junior College",
      subtitle: "Higher Secondary Education - Intermediate, MPC(Mathematics • Physics • Chemistry)",
      logo_path: "sasi.png",
      alt_name: "SASI Educational Institutions - Velivennu",
      duration: "Jun 2015 - Apr 2017",
      location: "Velivennu, Andhra Pradesh, India",
      descriptions: [
        "🎓 Marks: 967/1000 • Equivalent GPA: 3.8/4.0",
      ],
      website_link: "https://www.sasi.edu.in/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "The Complete web developer | CMS project",
      subtitle: "Udemy",
      logo_path: "udemy.jpeg",
      certificate_link:
        "https://www.udemy.com/certificate/UC-RCJYDL7M/",
      alt_name: "Udemy",
      color_code: "#ffffff",
    },
    {
      title: "Introduction to Programming Using Python",
      subtitle: "DataCamp",
      logo_path: "datacamp.jpeg",
      certificate_link:
        "https://www.datacamp.com/statement-of-accomplishment/course/b59be0aabcd64fc028d630b3693508d110f8119f",
      alt_name: "DataCamp",
      color_code: "#ffffff",
    },
    {
      title: "Intermediate Python",
      subtitle: "DataCamp",
      logo_path: "datacamp.jpeg",
      certificate_link:
        "https://www.datacamp.com/statement-of-accomplishment/course/af7f32c1aa988c0ae64ba1bf591f94b850f28ce8",
      alt_name: "DataCamp",
      color_code: "#ffffff",
    },
    {
      title: "Using python to interact with Operating System",
      subtitle: "Google",
      logo_path: "google_logo.png",
      certificate_link:
        "https://www.coursera.org/verify/GF6J7SK3U6E7",
      alt_name: "google",
      color_code: "#ffffff",
    },
    {
      title: "Infosys Certified Mongo Developer",
      subtitle: "Infosys",
      logo_path: "infosys.jpeg",
      certificate_link:
        "https://drive.google.com/file/d/1VjgK5J0P_U1pTEzpyP7RCQh_DApGv97v/view?usp=sharing",
      alt_name: "",
      color_code: "#ffffff",
    },
    {
      title: "Infosys Certified Front End Web Developer",
      subtitle: "Infosys",
      logo_path: "infosys.jpeg",
      certificate_link:
        "https://drive.google.com/file/d/1Vl1yVSvtioHkVNMT4adJQRYkx80f8unP/view?usp=sharing",
      alt_name: "infosys",
      color_code: "#ffffff",
    },
    {
      title: "Infosys Certified React Professional",
      subtitle: "Infosys",
      logo_path: "infosys.jpeg",
      certificate_link:
        "https://drive.google.com/file/d/1VkqU51N6OO945pDlpBEPUe8VlwrL9_gv/view?usp=sharing",
      alt_name: "infosys",
      color_code: "#ffffff",
    },
    {
      title: "Infosys Certified NodeJS Professinal",
      subtitle: "Infosys",
      logo_path: "infosys.jpeg",
      certificate_link:
        "https://drive.google.com/file/d/1VhNAGAUGhpBmpbjv657U5QaS9a7UF3uL/view?usp=sharing",
      alt_name: "infosys",
      color_code: "#ffffff",
    },
    {
      title: "Introduction to Agile methodology",
      subtitle: "Infosys",
      logo_path: "infosys.jpeg",
      certificate_link:
        "https://drive.google.com/file/d/1_bxVIfBoI_AAj6f985BKVlDGh5YsK73y/view?usp=sharing",
      alt_name: "infosys",
      color_code: "#ffffff",
    },
    {
      title: "Infosys Certified Open Chain Practitioner",
      subtitle: "Infosys",
      logo_path: "infosys.jpeg",
      certificate_link:
        "https://drive.google.com/file/d/1vN1Lnl2r684c1Bo_2JIbuMCO66v1FDSI/view",
      alt_name: "infosys",
      color_code: "#ffffff",
    },
    {
      title: "Continuous Integration and Delivery - DevOps",
      subtitle: "Infosys",
      logo_path: "infosys.jpeg",
      certificate_link:
        "https://drive.google.com/file/d/1DV4fXfZPzjc8iYymlex_8TGZ0dPET54g/view?usp=sharing",
      alt_name: "infosys",
      color_code: "#ffffff",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I have a strong background as a Full-Stack Developer, working with different companies on various projects. I have also enhanced my skills as a Software Engineer in some reputable companies.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Full-Time",
      work: true,
      experiences: [
        {
          title: "Digital Specialist Engineer L1",
          company: "Infosys",
          company_url: "https://infosys.com/",
          logo_path: "infosys.jpeg",
          duration: "Jan 2023 - Present",
          location: "Hyderabad, Telangana",
          description:
            "Working on Development and maintainance of Unified ETA, a distributed systems application for Toyota Motor North America",
          color: "#0879bf",
          certificate_link:
            "",
        },
      ],
    },
    {
      title: "Full-Time",
      work: true,
      experiences: [
        {
          title: "Digital Specialist Engineer L0",
          company: "Infosys",
          company_url: "https://infosys.com/",
          logo_path: "infosys.jpeg",
          duration: "Oct 2021 - Dec 2022",
          location: "Hyderabad, Telangana",
          description:
            "Working on Development and maintainance of Unified ETA, a distributed systems application for Toyota Motor North America, tracking every vehicle's journey from factory to dealer; ensuring accurate updates and estimated arrival dates, resulting in streamlined logistics and improved customer satisfaction.",
          color: "#0879bf",
          certificate_link:
            "",
        },
      ],
    },
    {
      title: "Internship",
      experiences: [
        {
          title: "Full Stack Developer",
          company: "Arum Innovations Pvt. Ltd",
          company_url: "https://aruminnovations.com/",
          logo_path: "arum_innovations.jpg",
          duration: "Feb 2021 - Sep 2021",
          location: "Kakinada, Andhra Pradesh, India",
          description:
            "I developed and implemented an innovative solution for streamlining internship certificate management using various web technologies and services. My solution included an admin panel and web application that automated verification, real-time tracking, and secure document storage of internship certificates. It also allowed secure access and editing of employee records and certificate templates, each with a unique certificate number. The certificates could be easily verified on the company website by entering the certificate number. This solution reduced administrative workload by 40%, improved certificate accuracy, and enhanced satisfaction and trust of the interns and future employers. It also boosted the reputation and brand image of the company as a provider of quality internship programs and innovative solutions.",
          color: "#000000",
          certificate_link:
            "https://internship.aruminnovations.com/InternShip/certificate.php?cno=AI202109AB0101",
        },
        {
          title: "Web Developer Intern",
          company: "Arum Innovations Pvt. Ltd",
          company_url: "https://aruminnovations.com/",
          logo_path: "arum_innovations.jpg",
          duration: "Apr 2020 - Jun 2020",
          location: "Kakinada, Andhra Pradesh, India",
          description:
            "I worked in the design and development of a web application that provides the functionality of generating and validating internship certificates.",
          color: "#ee3c26",
          certificate_link:
            "https://internship.aruminnovations.com/InternShip/certificate.php?cno=AI202006AB0101",
        },
      ],
    },
  ],
};

const publications = {
  data: [
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzQ=",
      name: "Implementation of Automatic Detection of Text from Complex Images and Converting to Semantic Speech",
      createdAt: "2022-01-30T16:26:54Z",
      description: "Lecture Notes in Networks and Systems, (Vol. 341), pp. 731-738.",
      url:
        "https://link.springer.com/chapter/10.1007/978-981-16-7118-0_62",
      publisher: "Springer",
      icon: "academicons:springer",
      coverImageUrl: "https://media.springernature.com/full/springer-static/cover-hires/book/978-981-16-7118-0",
      authors: "Santoshi G. (Asst Prof.), Srinivas Y. (Prof), & Sri Harsha M. (2022).",
      doi: "https://doi.org/10.1007/978-981-16-7118-0_62",
      subText: "•	 My research paper was the topic of my presentation at the ICRTC-2021, the 9th International Conference on Recent Trends in Computing, which took place in Delhi.",
    },
  ],
};

const recognition = {
  data: [
    {
      id: "4",
      name: "Certificate of Appreciation",
      createdAt: "2022-01-30T16:26:54Z",
      url:
        "https://drive.google.com/file/d/1swCUCUQhGp7vcjvVKLRzTxUrroZAdbbi/preview",
      issuer: "Toyota Motor North America",
      icon: "simple-icons:toyota",
      style: "text-danger",
      coverImageUrl: "Certificate of Appreciation.jpg",
      subText: "🏆 Our team received high praise from Toyota Motor North America during their visit to our Infosys DC.",
    },
    {
      id: "3",
      name: "RISE INSTA AWARDS NOVEMBER 2024",
      createdAt: "2022-01-30T16:26:54Z",
      url:
        "https://drive.google.com/file/d/15WAm-xtOdyVKewUp8Wl8ow0KFaohlHcB/preview",
      issuer: "Infosys",
      icon: "simple-icons:infosys",
      style: "text-primary",
      coverImageUrl: "Certificate.RISE.NOV24.png",
      subText: "🏆 I was awarded the RISE INSTA award by Infosys for my remarkable contribution to the Unified ETA project yet again for Toyota Motor North America.",
    },
    {
      id: "2",
      name: "RISE INSTA AWARDS NOVEMBER 2023",
      createdAt: "2022-01-30T16:26:54Z",
      url:
        "https://drive.google.com/file/d/1ni5sUCj74rO2VtstVvUXYf87d46vPawj/preview",
      issuer: "Infosys",
      icon: "simple-icons:infosys",
      style: "text-primary",
      coverImageUrl: "SriHarshaMullapudi.png",
      subText: "🏆 I was awarded the RISE INSTA award by Infosys for my remarkable contribution to the Unified ETA project for Toyota Motor North America.",
    },
    {
      id: "1.1",
      name: "CLOUD PROFESSIONAL",
      createdAt: "2022-01-30T16:26:54Z",
      url:
        "https://drive.google.com/file/d/1nkzht0aV1OOSfWQF3sMSq9ZQYwqhLKlc/preview",
      issuer: "Infosys",
      icon: "simple-icons:infosys",
      style: "text-primary",
      coverImageUrl: "CLD37GBPRFSL.png",
      subText: "🏆 In recognition of my skills and expertise, I was awarded the CLOUD PROFESSIONAL Skill Tag by Infosys.",
    },
    {
      id: "1.1",
      name: "OPEN SOURCE PROFESSIONAL",
      createdAt: "2022-01-30T16:26:54Z",
      url:
        "https://drive.google.com/file/d/18klggZ5fqtGlSbMnNsZi0fSZWFIbeGN8/preview",
      issuer: "Infosys",
      icon: "simple-icons:infosys",
      style: "text-primary",
      coverImageUrl: "OPNSRC46GBPR.png",
      subText: "🏆 In recognition of my skills and expertise, I was awarded the OPEN SOURCE PROFESSIONAL Skill Tag by Infosys.",
    },
    {
      id: "1",
      name: "MERIT SCHOLARSHIP AWARDS 2017 - 2021",
      createdAt: "2022-01-30T16:26:54Z",
      url:
        "https://drive.google.com/file/d/1QolnJwkSsKIC33iYy8JKZcuoB0JPtL80/preview",
      issuer: "A.P State Government",
      icon: "mingcute:government-fill",
      style: "text-secondary",
      coverImageUrl: "scholarship.png",
      subText: "🏆 My rank of 4998 (Top 5%) in AP State EAMCET 2017 Entrance Exam earned me a full scholarship from the state government which covered my full tuition and living expenses for the duration of my BTech course.",
    },
  ]
}

export {
  seo,
  skills,
  degrees,
  certifications,
  experience,
  publications,
  contact,
  recognition
};
