import React, { Component } from 'react';
import './Experience.css';
import TextTruncate from '../TextTruncate/TextTruncate';

class Experience extends Component {
  constructor(props) {
    super(props)
    this.state = {
      height: 0
    }
  }
  componentDidMount() {
    this.setState({ height: this.divElement.clientHeight });
  }

  render() {
    const experience = this.props.experience;
    const index = this.props.index;
    const totalCards = this.props.totalCards;
    const title = this.props.title;

    function calculateDateDifference(dateRange) {
      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

      function parseDate(dateStr) {
        const [month, year] = dateStr.split(" ");
        return new Date(year, months.indexOf(month));
      }

      const [startStr, endStr] = dateRange.split(" - ");
      const startDate = parseDate(startStr);
      const endDate = endStr === "Present" ? new Date() : parseDate(endStr);

      let years = endDate.getFullYear() - startDate.getFullYear();
      let monthsDiff = endDate.getMonth() - startDate.getMonth() + 1;

      if (monthsDiff < 0) {
        years--;
        monthsDiff += 12;
      }

      let result = "";
      if (years > 0) {
        result += years + (years === 1 ? " yr " : " yrs ");
      }
      if (monthsDiff > 0) {
        result += monthsDiff + (monthsDiff === 1 ? " mo" : " mos");
      }

      return result.trim();
    }

    return (
      <div className="experience-list-item" ref={(divElement) => { this.divElement = divElement }} style={{ marginTop: (index === 0 ? 0 : 0) }}>
        <div style={{display: "contents"}}>
          <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
            <div className="experience-card mb-3 p-1 bg-tertiary" style={{ flexGrow: 1 }}>
              <div>
                <h4 className="experience-card-title px-2 mb-1 experience-text-gradient" style={{ color: "black" }}>{experience["title"]}</h4>
                <div className="experience-card-header" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <div>
                    <div>
                      <p className="experience-card-company px-2 text-secondary">
                        <a className='text-secondary' href={experience["company_url"]} target="_blank" rel="noopener noreferrer">
                          {experience["company"] + " • " + calculateDateDifference(experience["duration"])}
                        </a><br />
                        <span className='minimal text-secondary'>{title}</span>
                        &nbsp;<span className="iconify minimal text-secondary" data-icon="material-symbols:work"></span>
                        <span className="iconify minimal" data-icon="bi:dash" style={experience["certificate_link"] ? {} : { color: "#80808000" }}></span>
                        <a className={experience["certificate_link"] ? "minimal text-secondary" : 'minimal'} href={experience["certificate_link"]} target="_blank" rel="noopener noreferrer" style={experience["certificate_link"] ? {} : { pointerEvents: 'none', color: "#80808000" }}>
                          view certificate&nbsp;<span className="iconify minimal" data-icon="mingcute:certificate-fill"></span>
                        </a>
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="experience-card-heading-right px-2">
                      <p className="experience-card-duration">{experience["duration"]}</p>
                      <p className="experience-card-location">{experience["location"]}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='custom-card-body p-3 mt-2' style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: 20 }}>
                <p className='text-align-justify text-secondary-emphasis' style={{ color: "black" }}>
                  <TextTruncate text={experience["description"]} />
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>

    );
  }
}

export default Experience;
